<template>
  <div class="home">
    <Header />
    <b-container>
      <b-row id="grid-filter">
        <b-col cols="12" class="text-right">
          <b-button v-b-modal.submit-change-password
            >Submeter troca de senha</b-button
          >
        </b-col>
        <b-col cols="12">
          <hr />
          <b-input-group class="mt-3">
            <template #append>
              <b-input-group-text class="btn-search"
                ><b-icon icon="search"></b-icon
              ></b-input-group-text>
            </template>
            <b-form-input
              type="search"
              v-model="search"
              @change="startSearch"
              placeholder="Pesquisa por CPF"
            ></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>

      <div class="grid-div">
        <b-table-simple striped hover class="grid-table" ref="table">
          <b-thead>
            <b-tr>
              <b-th>Documento</b-th>
              <b-th>Serial</b-th>
              <b-th>Aparelho</b-th>
              <b-th>Modelo</b-th>
              <b-th>Ip</b-th>
              <b-th>Status</b-th>
              <b-th>Data e Hora</b-th>
              <b-th class="actions">Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(item, index) in items"
              :key="index"
              :data-index="index"
            >
              <b-td @click="findClick(item)" class="view">{{
                item.document
              }}</b-td>
              <b-td
                ><div class="serial-id">
                  <abbr :title="item.serialdevice">{{
                    item.serialdevice || "-"
                  }}</abbr>
                </div></b-td
              >
              <b-td>{{ item.manufacturer }}</b-td>
              <b-td>{{ item.model }}</b-td>
              <b-td>{{ item.ipaddress }}</b-td>
              <b-td>{{ item.status }}</b-td>
              <b-td>{{
                new Date(item.timestamp).toLocaleString("pt-BR")
              }}</b-td>
              <b-td>
                <b-row cols="1" cols-sm="1" class="text-center h5 mb-0">
                  <b-col>
                    <b-icon
                      v-show="item.status === 'BLOQUEADO' ? true : false"
                      icon="check2-circle"
                      variant="success"
                      scale="1.0"
                      class="ic-edit"
                      @click="callModalApproved(item)"
                    >
                    </b-icon>
                    <b-icon
                      v-show="item.status === 'ATIVO' ? true : false"
                      icon="x-circle"
                      variant="danger"
                      scale="0.9"
                      class="ic-history"
                      @click="callModalDisapprove(item)"
                    >
                    </b-icon>
                  </b-col>
                </b-row>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <infinite-loading
        @infinite="infiniteHandler"
        v-show="!items.length || items.length > 10"
      ></infinite-loading>
    </b-container>

    <b-modal
      id="submit-change-password"
      ref="modal"
      title="Envio do e-mail da troca de senha"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="submitChangePassword">
        <b-form-group
          label="CPF"
          label-for="document"
          invalid-feedback="Informe o CPF"
          :state="isCpf(form.document)"
        >
          <b-form-input
            id="document"
            v-model="form.document"
            :state="isCpf(form.document)"
            v-mask="'###########'"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="E-mail"
          label-for="email"
          invalid-feedback="Informe o Email"
          :state="isEmail(form.email)"
        >
          <b-form-input
            id="email"
            v-model="form.email"
            :state="isEmail(form.email)"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Confirme o e-mail"
          label-for="email2"
          invalid-feedback="Os emails não coincidem"
          :state="isEmailMatch(form.email2)"
        >
          <b-form-input
            id="email2"
            v-model="form.email2"
            :state="isEmailMatch(form.email, form.email2)"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "List",
  components: {
    Header,
    InfiniteLoading,
  },
  created: async function () {},
  data() {
    return {
      file: null,
      items: [],
      count: 0,
      currentPage: 0,
      modeProfile: "visualization",
      document: "",
      search: "",
      lockUnlockDevice: {
        document: "",
        serial: "",
      },
      form: {
        document: "",
      },
      showAproveAndReproveBtns: false,
      showAproveTrue: true,
    };
  },
  mounted() {
    this.$root.pessoaId = sessionStorage.pessoaId;
    if (!sessionStorage.getItem("token")) {
      this.$router.push("/");
    }
  },
  methods: {
    getData() {
      if (!this.search) {
        return this.axios.get(
          "/safety/findall/devices?page=" + this.count + "&pageSize=25",
          { headers: { Token: sessionStorage.getItem("token") } }
        );
      } else {
        return this.axios.post(
          "/safety/find/devices",
          { document: this.search },
          { headers: { Token: sessionStorage.getItem("token") } }
        );
      }
    },
    infiniteHandler($state) {
      this.$state = $state;
      this.count++;
      this.getData()
        .then((resp) => {
          const res = JSON.parse(resp.data.json);

          if (res.length) {
            this.currentPage = this.count;
            $state.loaded();
            if (this.search) {
              this.items = res;
            } else {
              this.items.push(...res);
            }
          } else {
            $state.complete();
          }
        })
        .catch($state.complete);
    },
    startSearch() {
      this.count = 0;
      this.currentPage = 0;
      this.items = [];
      this.$state.reset();
    },
    callModalApproved(item) {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja aprovar o dispositivo?", {
          title: "Aprovar dispositivo",
          centered: true,
          okVariant: "primary",
          okTitle: "APROVAR",
          cancelVariant: "outline-primary",
          cancelTitle: "NÃO",
          hideHeaderClose: false,
          footerClass: "p-2",
        })
        .then((resp) => {
          if (resp) {
            this.approveData(item);
          }
        })
        .catch((err) => {
          this.$root.$emit("notify", { label: err, time: 10, type: "primary" });
        });
    },
    approveData(data) {
      const body = this.lockUnlockDevice;
      body.document = data.document;
      body.serial = data.serialdevice;

      this.axios.post("/safety/release/device", body, {
        headers: { Token: sessionStorage.getItem("token") },
      });

      this.$bvModal
        .msgBoxOk("O dispositivo foi aprovado com sucesso.", {
          title: "Dispositivo aprovado",
          size: "lg",
          okVariant: "primary",
          okTitle: "FECHAR",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(() => {
          this.startSearch();
        });
    },
    callModalDisapprove(item) {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja reprovar o dispositivo?", {
          title: "Reprovar dispositivo",
          centered: true,
          okVariant: "danger",
          okTitle: "REPROVAR",
          cancelVariant: "outline-danger",
          cancelTitle: "NÃO",
          hideHeaderClose: false,
          footerClass: "p-2",
        })
        .then((resp) => {
          if (resp) {
            this.disapproveData(item);
          }
        })
        .catch((err) => {
          this.$root.$emit("notify", { label: err, time: 10, type: "danger" });
        });
    },
    disapproveData(data) {
      const body = this.lockUnlockDevice;
      body.document = data.document;
      body.serial = data.serialdevice;

      this.axios.post("/safety/lock/device", body, {
        headers: { Token: sessionStorage.getItem("token") },
      });

      this.$bvModal
        .msgBoxOk("O dispositivo foi reprovado com sucesso.", {
          title: "Dispositivo reprovado",
          size: "lg",
          okVariant: "danger",
          okTitle: "FECHAR",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(() => {
          this.startSearch();
        });
    },
    findClick(data) {
      this.search = data.document;
      this.startSearch();
      this.$state.complete;
    },
    submitChangePassword() {
      if (!this.checkFormValidity(this.form.document)) {
        return;
      }
      this.$nextTick(() => {
        this.$bvModal.hide("submit-change-password");
      });
      this.axios
        .post(
          "/safety/password/email/send",
          { document: this.form.document, email: this.form.email },
          { headers: { Token: sessionStorage.getItem("token") } }
        )
        .then(() => {
          this.$root.$emit("notify", {
            label: "Email enviado com sucesso",
            time: 5,
            type: "success",
          });
        })
        .catch((error) => {
          this.$root.$emit("notify", {
            label: error.response.data.errors[0].message,
            time: 5,
            type: "danger",
          });
        });
    },
    checkFormValidity() {
      return (
        this.isCpf(this.form.document) &&
        this.isEmail(this.form.email) &&
        this.isEmailMatch(this.form.email, this.form.email2)
      );
    },
    isCpf(strCPF) {
      if (strCPF) {
        let sum = 0,
          leftovers,
          i;
        if (strCPF == "00000000000") return false;

        for (i = 1; i <= 9; i++)
          sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        leftovers = (sum * 10) % 11;

        if (leftovers == 10 || leftovers == 11) leftovers = 0;
        if (leftovers != parseInt(strCPF.substring(9, 10))) return false;

        sum = 0;
        for (i = 1; i <= 10; i++)
          sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        leftovers = (sum * 10) % 11;

        if (leftovers == 10 || leftovers == 11) leftovers = 0;
        if (leftovers != parseInt(strCPF.substring(10, 11))) return false;
        return true;
      }
    },
    isEmail(email) {
      
      if (email) {
        const emailRegex = /^([./_a-zA-Z0-9./-]+)@([a-zA-Z]+)\.([a-zA-Z]){2,8}$/;
        const emailRegex2 = /^([./_a-zA-Z0-9./-]+)@([a-zA-Z]+)\.([a-zA-Z]){2,8}\.[a-zA-Z]{1,3}$/;
        
        
        if (email.includes(".com.br")) {
          return emailRegex2.test(email);
        } else if (email.includes(".br")) {
          return emailRegex2.test(email);
        } else {
          return emailRegex.test(email);
        }
      }
    },
    isEmailMatch(email, email2) {
      if (email && email2) {
        return email === email2;
      }
    },
    resetModal() {
      this.form = {};
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.submitChangePassword();
    },
  },
};
</script>
<style scoped>
#grid-filter {
  margin-bottom: 22px;
}
.actions button,
.actions #dropdown-header {
  margin-right: 10px;
}
.btn-icon svg {
  position: relative;
  margin-right: 5px;
  top: -3px;
  height: 16px;
}
.grid-div {
  border-radius: 16px;
}
.grid-div .table thead th {
  border-top: none;
}
.grid-div .table thead th {
  border-right: solid 1px #dee2e6;
}
.grid-table {
  border: solid;
  border-width: 1px;
  border-color: #dadada;
  border-radius: 15px;
}
.grid-table .actions {
  width: 100px;
}
.view:hover {
  color: #007bff;
  cursor: pointer;
}
.aplicar-btn {
  background-color: #1e2a4f;
}
.input-group-append :hover {
  cursor: pointer;
}
.ic-edit,
.ic-history,
.ic-excluir:hover {
  cursor: pointer;
}
.view:hover {
  color: #007bff;
  cursor: pointer;
}
abbr {
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}
.serial-id {
  width: 150px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
